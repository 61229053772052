import React from 'react'
import LandingPage from '../components/LandingPage'

const index = () => {
  return (
   <>
   <LandingPage/>
   </>
  )
}

export default index
