import { memo } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from ".";
export default memo(() => {
  return (
    <>
    <Navbar/>
      <Outlet/>
    </>
  );
});