import React from 'react'
import logokalibri from '../assets/logo/logokalibri2.png'
const Navbar = () => {
  return (
    <>
    <div className='navbar-header-wraper'>
        <div className='container'>
             <img src={logokalibri} alt='logo-navbar' className='navbar-header-wraper-logo'/>
        </div>
    </div>
    </>
  )
}

export {
    Navbar
}
