import React from 'react'
import backgroundmap from '../../assets/image/map2.png'
import listyellow from '../../assets/image/list.png'
import banner from '../../assets/image/banner3.png'
import banner2 from '../../assets/image/banner4.png'
import info from '../../assets/image/info.png'
const LandingPage = () => {
    const whatsappLink = "https://wa.link/i8aq07";
  return (
    <>
    <div className='container'>
        <div className='content-landingpage-wraper'>
            <div className='content-landingpage-wraper-info'>
                <div className='text-center mt-5 py-3'>
                    <h1 className='content-landingpage-wraper-title'>
                        We’re sorry, 
                    </h1>
                    <p className='content-landingpage-wraper-paragraph content-landingpage-wraper-margin'>
                    We are under Maintenance. We are improving our website. We’ll be back soon
                    </p>
                    <img src={listyellow} alt='' className='image-list-yellow-wraper'/>
                </div>
            </div>
        </div>
        <div className='content-landingpage-wraper-vertical'>
            <div className='d-flex'>
            <a href={whatsappLink}>
                <img src={banner} alt='' className='image-banner-wraper image-banner-margin' />
            </a>
            <a href={whatsappLink}>
                <img src={banner} alt='' className='image-banner-wraper image-banner-margin' />
            </a>
            <a href={whatsappLink}>
                <img src={banner} alt='' className='image-banner-wraper image-banner-margin' />
            </a>
            <a href={whatsappLink}>
                <img src={banner} alt='' className='image-banner-wraper image-banner-margin' />
            </a>
          
                {/* <Link to={''}><img src={banner} alt='' className='image-banner-wraper d-md-block d-none' /></Link> */}
                {/* <Link to='https://elspasial.tech/'><img src={banner} alt='' className='image-banner-wraper image-banner-margin' /></Link>
                <Link to={''}><img src={banner} alt='' className='image-banner-wraper image-banner-margin' /></Link>
                <Link to={''}><img src={banner} alt='' className='image-banner-wraper image-banner-margin' /></Link>
                <Link to={''}><img src={banner} alt='' className='image-banner-wraper image-banner-margin' /></Link> */}
                {/* <img src={banner} alt='' className='image-banner-wraper d-md-block d-none' />
                <img src={banner} alt='' className='image-banner-wraper image-banner-margin' />
                <img src={banner} alt='' className='image-banner-wraper image-banner-margin' style={{cursor:'pointer'}}/>
                <img src={banner} alt='' className='image-banner-wraper image-banner-margin' style={{cursor:'pointer'}}/> */}
            </div>
        </div>
        <img src={info} alt='' className='image-info'/>
    </div>
    <div className='content-landingpage-wraper-vertical-small'>
            <img src={banner2} alt='' className='image-banner-small-wraper'/>
        </div>
    <div className='information-yellow-wraper'>
        <div className='information-yellow-wraper-padding'>
            <h1 className='information-yellow-wraper-text text-center'>Information</h1>
        </div>
    </div>
    <img src={backgroundmap} alt='' className='image-background-wraper d-md-block d-none'/>
    </>
  )
}

export default LandingPage
